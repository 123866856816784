import React from 'react';
import classnames from 'classnames';
import { LinkData } from '../appTypes';
import { ShText } from '@shoootin/translations';
import { scrollToId } from '../utils/domUtils';

const AppLinkBar = ({
  links,
  modifier,
  scroll,
}: {
  links: LinkData[];
  modifier?:
    | 'tabset-nav'
    | 'tabset-nav left'
    | 'swiper-container-horizontal swiper-container-free-mode';
  scroll?: boolean;
}) => {
  return (
    <div className={classnames('linkbar', 'swiper-container', modifier)}>
      <ul className={classnames('linkbar-wrapper', 'swiper-wrapper', modifier)}>
        {links.map((link, i) => (
          <li key={i} className="linkbar-link swiper-slide">
            <a
              className={classnames('link', modifier)}
              // href={link.href}
              // data-smooth-scroll={scroll ? true : undefined}
              onClick={() => {
                scroll && scrollToId(link.href);
              }}
            >
              <ShText message={link.label} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AppLinkBar;
